<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center class="adjustTop">
      <v-flex xs12 text-left class="hidden-sm-and-down">
        <v-img
          class="align-center"
          cover
          :height="
            $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'xl'
              ? '100%'
              : '600px'
          "
          :width="
            $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'xl'
              ? '100%'
              : '100%'
          "
          :src="require('./../../assets/images/banner.jpg')"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="#527E33"
              ></v-progress-circular>
            </v-row>
          </template>
          <v-layout wrap>
            <v-flex xs12 sm6 md4 lg6 text-left pa-4>
              <v-layout wrap fill-height>
                <!-- <v-flex xs12 align-self-start text-left style="color: #ffffff">
                  <v-layout wrap justify-start>
                    <v-flex align-self-start text-left xs2>
                      <router-link class="routerlink" :to="(name = '/')">
                        <v-img
                          contain
                          :height="
                            $vuetify.breakpoint.name == 'md' ? '160px' : '100px'
                          "
                          :src="require('./../../assets/images/wtilogo.png')"
                        ></v-img>
                      </router-link>
                    </v-flex>
                  </v-layout>
                </v-flex> -->

                <!-- <v-flex xs12 pt-16 align-self-center text-left style="color: #ffffff">
                  <span class="poppinssemibold" style="font-size: 29px">
                    HAPPY WORLD EARTH DAY; 22 nd April, 2023
                  </span>
                  <br />
                </v-flex> -->
              </v-layout>
            </v-flex>

            <v-flex xs12 sm6 md8 lg6 align-self-center>
              <v-card flat tile color="transparent">
                <v-layout wrap justify-end>
                  <v-flex xs12 sm10 align-self-center text-center pa-4>
                    <v-card
                      outlined
                      class="rounded-lg"
                      tile
                      style="background-color: #ffffff"
                    >
                      <v-layout wrap>
                        <v-flex xs12 align-self-center id="donateNow">
                          <Donationcard />
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>

      <v-flex xs12 text-left class="hidden-md-and-up" style="margin-top: 50px">
        <v-img
          class="align-center"
          cover
          height="100%"
          width="100%"
          :src="require('./../../assets/images/banner.jpg')"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="#527E33"
              ></v-progress-circular>
            </v-row>
          </template>
          <v-layout wrap pa-2>
            <!-- <v-flex xs12 align-self-end text-center style="color: #ffffff">
              <span class="poppinssemibold" style="font-size: 29px">
                HAPPY WORLD EARTH DAY; 22 nd April, 2023
              </span>
              <br />
            </v-flex> -->
            <v-flex xs12 align-self-center pa-sm-4>
              <Donationcard />
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Donationcard from "./donationCard";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
    };
  },
  components: {
    Donationcard,
  },
};
</script>
<style scoped>
@media only screen and (min-device-width: 375px) and (max-device-width: 424px) {
  .adjustTop {
    margin-top: 25px !important;
  }
}

@media only screen and (min-device-width: 425px) and (max-device-width: 704px) {
  .adjustTop {
    margin-top: 34px !important;
  }
}
</style>
