<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
      spinner="bar-fade-scale" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pa-md-0>
      <v-flex xs12 align-self-center style="margin-all: 0px">
        <v-layout wrap justify-center>
          <v-flex xs12 lg10 :style="
            $vuetify.breakpoint.name == 'xs'
              ? 'margin-top:0%'
              : 'padding-top:0%'
          " align-self-center>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex xs12 md12>
                    <v-card flat tile color="transparent">
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left pt-8 pa-4>
                          <v-layout wrap class="hidden-md-and-up">
                            <v-flex xs12 text-center>
                              <span style="font-size: 24px; color: #000" class="poppinsbold">
                                Investing in Our Planet
                              </span></v-flex>
                          </v-layout>
                          <v-layout wrap justify-center class="hidden-sm-and-down">
                            <v-flex xs12 text-center>
                              <span style="font-size: 20px; color: #000" class="poppinsbold">
                                Investing in Our Planet
                              </span></v-flex>
                          </v-layout>
                          <span class="poppinsregular" :style="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'font-size:17px;color:#000'
                              : 'font-size:19px;color:#000'
                          ">
                            <span :style="
                              $vuetify.breakpoint.name == 'md'
                                ? 'font-size:14px'
                                : 'font-size:16px'
                            ">
                              Every year the world celebrates 22nd April as Earth Day. It is a day to re-examine
                              individual progress in fulfilling our commitment towards the environment. It is a day to
                              appreciate the resilience of our planet against the havoc wrecked by mankind. It is a day to
                              look beyond tomorrow.
                            </span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md4 align-self-center text-center pa-2 class="hidden-sm-and-down">
                    <span class="poppinsregular" :style="
                      $vuetify.breakpoint.name == 'xs'
                        ? 'font-size:17px;color:#000;'
                        : 'font-size:19px;color:#000;'
                    ">
                      <span :style="
                        $vuetify.breakpoint.name == 'md'
                          ? 'font-size:14px'
                          : 'font-size:16px'
                      ">
                        From the Pir Panjal Mountains in Kashmir to the mangrove forests of Kerala; from Himalayan black
                        bear forests in Arunachal to Whale Shark populations off the Gujarat coast –WTI is working on over
                        40 conservation projects spanning across India.
                      </span>
                    </span>
                  </v-flex>
                  <v-flex xs12 md4 align-self-center text-left pa-4 class="hidden-md-and-up">
                    <span class="poppinsregular" :style="
                      $vuetify.breakpoint.name == 'xs'
                        ? 'font-size:17px;color:#000;'
                        : 'font-size:19px;color:#000;'
                    ">
                      <span :style="
                        $vuetify.breakpoint.name == 'md'
                          ? 'font-size:14px'
                          : 'font-size:16px'
                      ">
                        From the Pir Panjal Mountains in Kashmir to the mangrove forests of Kerala; from Himalayan black
                        bear forests in Arunachal to Whale Shark populations off the Gujarat coast –WTI is working on over
                        40 conservation projects spanning across India.
                      </span>
                    </span>
                  </v-flex>
                  <v-flex xs12 md8>
                    <v-img contain :src="require('./../../assets/images/wtiprojectmapnew.jpg')">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <!-- <span style="
                          color: #6a768a;
                          font-style: italic;
                          font-size: 11px;
                          line-height: normal;
                        " class="poppinsbold">
                      One of the elephant calves, Dhola, with his animal keeper,
                      Tarun, at CWRC | Photo: Samshul Ali
                    </span> -->
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex xs12 md12>
                    <v-card flat tile color="transparent">
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left pa-4>
                          <v-layout wrap class="hidden-md-and-up">
                            <v-flex xs12 text-center>
                              <span style="font-size: 22px; color: #000" class="poppinsbold">
                                Our work on themes that have endured as part of the Earth Day tradition for the past 50+
                                years
                              </span></v-flex>
                          </v-layout>
                          <v-layout wrap justify-center class="hidden-sm-and-down">
                            <v-flex xs12 text-center>
                              <span style="font-size: 18px; color: #000" class="poppinsbold">
                                Some of our work on themes which have endured as part of the Earth Day tradition for the
                                past 50+ years.
                              </span></v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-layout wrap class="hidden-sm-and-down">
                    <v-flex xs12 md5 class="hidden-sm-and-down" pa-4>
                      <v-img contain height="450px" width="450px" :src="require('./../../assets/images/coastal.jpg')">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                    <v-flex xs12 md6 class="hidden-md-and-up" text-left align-self-start pa-4>
                      <v-img contain :src="require('./../../assets/images/coastal.jpg')">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                    <v-flex xs12 md7 align-self-center pa-4>
                      <!-- <v-layout wrap class="hidden-md-and-up">
                        <v-flex xs12 text-left>
                          <span style="font-size: 20px; color: #000" class="poppinsbold">
                            Coastal clean-up drives
                          </span></v-flex>
                      </v-layout> -->
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-center>
                          <span style="font-size: 20px; color: #000" class="poppinsbold">
                            Coastal clean-up drives
                          </span></v-flex>
                      </v-layout>
                      <v-flex xs12 md12 text-left>
                        <span class="poppinsregular" :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:17px;color:#000'
                            : 'font-size:19px;color:#000'
                        ">
                          <span :style="
                            $vuetify.breakpoint.name == 'md'
                              ? 'font-size:14px'
                              : 'font-size:16px'
                          ">
                            <span class="poppinsbold">8,005 kg</span> of marine debris removed off the coasts of Kerala
                            and Karnataka in as a part of WTI’s
                            <a href="https://www.wti.org.in/projects/whale-shark-conservation-kerala-lakshadweep/"
                              target="_blank"><span style="color: #03a5fc">
                                <u> <b> Save the Whale Shark</b></u></span></a> campaign. <br>
                            We are campaigning among the fishing community of west coast (Gujarat, Karnataka and Kerala)
                            to
                            attain ZERO accidental killing of whale sharks and to keep the marine habitat free of
                            plastics.

                          </span>
                        </span>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap class="hidden-md-and-up">
                    <v-flex xs12 md7 align-self-center pa-4>
                      <!-- <v-layout wrap class="hidden-md-and-up">
                        <v-flex xs12 text-left>
                          <span style="font-size: 20px; color: #000" class="poppinsbold">
                            Coastal clean-up drives
                          </span></v-flex>
                      </v-layout> -->
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-center>
                          <span style="font-size: 20px; color: #000" class="poppinsbold">
                            Coastal clean-up drives
                          </span></v-flex>
                      </v-layout>
                      <v-flex xs12 md12 text-left>
                        <span class="poppinsregular" :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:17px;color:#000'
                            : 'font-size:19px;color:#000'
                        ">
                          <span :style="
                            $vuetify.breakpoint.name == 'md'
                              ? 'font-size:14px'
                              : 'font-size:16px'
                          ">
                            <span class="poppinsbold">8,005 kg</span> of marine debris removed off the coasts of Kerala
                            and Karnataka in as a part of WTI’s
                            <a href="https://www.wti.org.in/projects/whale-shark-conservation-kerala-lakshadweep/"
                              target="_blank"><span style="color: #03a5fc">
                                <u> <b> Save the Whale Shark</b></u></span></a> campaign. <br>
                            We are campaigning among the fishing community of west coast (Gujarat, Karnataka and Kerala)
                            to
                            attain ZERO accidental killing of whale sharks and to keep the marine habitat free of
                            plastics.

                          </span>
                        </span>
                      </v-flex>
                    </v-flex>
                    <v-flex xs12 md5 class="hidden-sm-and-down" pa-4>
                      <v-img contain height="450px" width="450px" :src="require('./../../assets/images/coastal.jpg')">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                    <v-flex xs12 md6 class="hidden-md-and-up" text-left align-self-start pa-4>
                      <v-img contain :src="require('./../../assets/images/coastal.jpg')">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center class="hidden-sm-and-down">
                    <v-flex xs12 md5 pa-4 text-center class="hidden-sm-and-down">
                      <v-card flat justify-center>
                        <OwlCarousel :nav="false" :dots="true" :responsive="owlResponsive">
                          <template xs12 v-for="(item, i) in Habitat">
                            <v-card flat :key="i">
                              <v-img :src="item.image" height="450px" width="450px" contain eager> <template
                                  v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                                  </v-row>
                                </template></v-img>
                            </v-card>
                          </template>
                        </OwlCarousel>
                      </v-card>
                      <!-- <v-img contain height="450px" width="450px" :src="require('./../../assets/images/habitat.jpg')">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img> -->
                    </v-flex>
                    <v-flex xs12 md6 text-left pa-4 class="hidden-md-and-up">
                      <v-card flat justify-center>
                        <OwlCarousel :nav="false" :dots="true" :responsive="owlResponsive">
                          <template xs12 v-for="(item, i) in Habitat">
                            <v-card flat :key="i">
                              <v-img :src="item.image" contain eager> <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                                  </v-row>
                                </template></v-img>
                            </v-card>
                          </template>
                        </OwlCarousel>
                      </v-card>
                      <!-- <v-img contain :src="require('./../../assets/images/habitat.jpg')">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img> -->
                    </v-flex>
                    <v-flex xs12 md7 align-self-center pa-4>
                      <!-- <v-layout wrap class="hidden-md-and-up">
                        <v-flex xs12 text-left>
                          <span style="font-size: 20px; color: #000" class="poppinsbold">
                            Habitat Restoration
                          </span></v-flex>
                      </v-layout> -->
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-center>
                          <span style="font-size: 20px; color: #000" class="poppinsbold">
                            Habitat Restoration
                          </span></v-flex>
                      </v-layout>
                      <v-flex xs12 md12 text-left>
                        <span class="poppinsregular" :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:17px;color:#000'
                            : 'font-size:19px;color:#000'
                        ">
                          <span :style="
                            $vuetify.breakpoint.name == 'md'
                              ? 'font-size:14px'
                              : 'font-size:16px'
                          ">
                            <span class="poppinsbold">11 acres</span> of mangrove forests under private ownership in the
                            Kannur district of Kerala secured
                            as
                            a part of WTI’s <a href="https://www.wti.org.in/projects/kannur-kandal-project/"
                              target="_blank"><span style="color: #03a5fc">
                                <u> <b> Kannur Kandal Recovery</b></u></span></a> project. <br>
                            <br>
                            <span class="poppinsbold">654.33 acres</span> of community-owned land set aside and restored
                            as ‘Village Reserve Forests’ by
                            the
                            Garo people of Meghalaya as part of the <a
                              href="https://www.wti.org.in/projects/garo-green-spine-conservation-project/"
                              target="_blank"><span style="color: #03a5fc">
                                <u> <b>Garo Green Spine</b></u></span></a> project.
                          </span>
                        </span>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center class="hidden-md-and-up">
                 
                    <v-flex xs12 md7 align-self-center pa-4>
                      <!-- <v-layout wrap class="hidden-md-and-up">
                        <v-flex xs12 text-left>
                          <span style="font-size: 20px; color: #000" class="poppinsbold">
                            Habitat Restoration
                          </span></v-flex>
                      </v-layout> -->
                      <v-layout wrap justify-center pt-5>
                        <v-flex xs12 text-center>
                          <span style="font-size: 20px; color: #000" class="poppinsbold">
                            Habitat Restoration
                          </span></v-flex>
                      </v-layout>
                      <v-flex xs12 md12 text-left>
                        <span class="poppinsregular" :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:17px;color:#000'
                            : 'font-size:19px;color:#000'
                        ">
                          <span :style="
                            $vuetify.breakpoint.name == 'md'
                              ? 'font-size:14px'
                              : 'font-size:16px'
                          ">
                            <span class="poppinsbold">11 acres</span> of mangrove forests under private ownership in the
                            Kannur district of Kerala secured
                            as
                            a part of WTI’s <a href="https://www.wti.org.in/projects/kannur-kandal-project/"
                              target="_blank"><span style="color: #03a5fc">
                                <u> <b> Kannur Kandal Recovery</b></u></span></a> project. <br>
                            <br>
                            <span class="poppinsbold">654.33 acres</span> of community-owned land set aside and restored
                            as ‘Village Reserve Forests’ by
                            the
                            Garo people of Meghalaya as part of the <a
                              href="https://www.wti.org.in/projects/garo-green-spine-conservation-project/"
                              target="_blank"><span style="color: #03a5fc">
                                <u> <b>Garo Green Spine</b></u></span></a> project.
                          </span>
                        </span>
                      </v-flex>
                    </v-flex>
                    <v-flex xs12 md5 pa-4 text-center class="hidden-sm-and-down">
                      <v-card flat justify-center>
                        <OwlCarousel :nav="false" :dots="true" :responsive="owlResponsive">
                          <template xs12 v-for="(item, i) in Habitat">
                            <v-card flat :key="i">
                              <v-img :src="item.image" height="450px" width="450px" contain eager> <template
                                  v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                                  </v-row>
                                </template></v-img>
                            </v-card>
                          </template>
                        </OwlCarousel>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 md5 text-left pa-4 class="hidden-md-and-up">
                      <v-card flat justify-center>
                        <OwlCarousel :nav="false" :dots="true" :responsive="owlResponsive">
                          <template xs12 v-for="(item, i) in Habitat">
                            <v-card flat :key="i">
                              <v-img :src="item.image" contain eager> <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                                  </v-row>
                                </template></v-img>
                            </v-card>
                          </template>
                        </OwlCarousel>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap class="hidden-sm-and-down">
                    <v-flex xs12 md5 text-left pa-4 class="hidden-sm-and-down">
                      <v-card flat justify-center>
                        <OwlCarousel :nav="false" :dots="true" :responsive="owlResponsive">
                          <template xs12 v-for="(list, j) in Community">
                            <v-card flat :key="j">
                              <v-img :src="list.image" height="450px" width="450px" contain eager> <template
                                  v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                                  </v-row>
                                </template></v-img>
                            </v-card>
                          </template>
                        </OwlCarousel>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 md5 text-left pa-4 class="hidden-md-and-up">
                      <v-card flat justify-center>
                        <OwlCarousel :nav="false" :dots="true" :responsive="owlResponsive">
                          <template xs12 v-for="(list, j) in Community">
                            <v-card flat :key="j">
                              <v-img :src="list.image" contain eager> <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                                  </v-row>
                                </template></v-img>
                            </v-card>
                          </template>
                        </OwlCarousel>
                      </v-card>
                      <!-- <v-img contain :src="require('./../../assets/images/community.jpg')">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img> -->
                    </v-flex>
                    <v-flex xs12 md7 align-self-center pa-4>
                      <!-- <v-layout wrap class="hidden-md-and-up">
                        <v-flex xs12 text-left>
                          <span style="font-size: 20px; color: #000" class="poppinsbold">
                            Community Support
                          </span></v-flex>
                      </v-layout> -->
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-center>
                          <span style="font-size: 20px; color: #000" class="poppinsbold">
                            Community Support
                          </span></v-flex>
                      </v-layout>
                      <v-flex xs12 md12 text-left>
                        <span class="poppinsregular" :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:17px;color:#000'
                            : 'font-size:19px;color:#000'
                        ">
                          <span :style="
                            $vuetify.breakpoint.name == 'md'
                              ? 'font-size:14px'
                              : 'font-size:16px'
                          ">
                            As a part of the <a href="https://www.wti.org.in/projects/kashmir-markhor-recovery-project/"
                              target="_blank"><span style="color: #03a5fc">
                                <u> <b>Kashmir Markhor Recovery</b></u></span></a> project, we are working with the local
                            communities
                            and
                            herders of Kashmir to conserve the Pir Panjal Markhor (the world’s largest goat). In return of
                            their support, we have linked over <span class="poppinsbold">800 herder families</span> with
                            different Government welfare
                            schemes. <br> <br>
                            WTI’s <a
                              href="https://www.wti.org.in/projects/protecting-tigers-people-and-their-vital-habitats-in-the-sundarban-delta-of-india-and-bangladesh/"
                              target="_blank"><span style="color: #03a5fc">
                                <u> <b> Sundarbans project</b></u></span></a> is working to mitigate human-tiger conflict
                            in the Sundarbans delta
                            of
                            West Bengal and reducing the dependence of local communities of forests. <span
                              class="poppinsbold">627 families</span> have
                            been
                            provided with alternative livelihood support as a part of the project.

                          </span>
                        </span>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap class="hidden-md-and-up">
                    <v-flex xs12 md7 align-self-center pa-4>
                      <!-- <v-layout wrap class="hidden-md-and-up">
                        <v-flex xs12 text-left>
                          <span style="font-size: 20px; color: #000" class="poppinsbold">
                            Community Support
                          </span></v-flex>
                      </v-layout> -->
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-center>
                          <span style="font-size: 20px; color: #000" class="poppinsbold">
                            Community Support
                          </span></v-flex>
                      </v-layout>
                      <v-flex xs12 md12 text-left>
                        <span class="poppinsregular" :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:17px;color:#000'
                            : 'font-size:19px;color:#000'
                        ">
                          <span :style="
                            $vuetify.breakpoint.name == 'md'
                              ? 'font-size:14px'
                              : 'font-size:16px'
                          ">
                            As a part of the <a href="https://www.wti.org.in/projects/kashmir-markhor-recovery-project/"
                              target="_blank"><span style="color: #03a5fc">
                                <u> <b>Kashmir Markhor Recovery</b></u></span></a> project, we are working with the local
                            communities
                            and
                            herders of Kashmir to conserve the Pir Panjal Markhor (the world’s largest goat). In return of
                            their support, we have linked over <span class="poppinsbold">800 herder families</span> with
                            different Government welfare
                            schemes. <br> <br>
                            WTI’s <a
                              href="https://www.wti.org.in/projects/protecting-tigers-people-and-their-vital-habitats-in-the-sundarban-delta-of-india-and-bangladesh/"
                              target="_blank"><span style="color: #03a5fc">
                                <u> <b> Sundarbans project</b></u></span></a> is working to mitigate human-tiger conflict
                            in the Sundarbans delta
                            of
                            West Bengal and reducing the dependence of local communities of forests. <span
                              class="poppinsbold">627 families</span> have
                            been
                            provided with alternative livelihood support as a part of the project.

                          </span>
                        </span>
                      </v-flex>
                    </v-flex>
                    <v-flex xs12 md5 text-left pa-4 class="hidden-sm-and-down">
                      <v-card flat justify-center>
                        <OwlCarousel :nav="false" :dots="true" :responsive="owlResponsive">
                          <template xs12 v-for="(list, j) in Community">
                            <v-card flat :key="j">
                              <v-img :src="list.image" height="450px" width="450px" contain eager> <template
                                  v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                                  </v-row>
                                </template></v-img>
                            </v-card>
                          </template>
                        </OwlCarousel>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 md5 text-left pa-4 class="hidden-md-and-up">
                      <v-card flat justify-center>
                        <OwlCarousel :nav="false" :dots="true" :responsive="owlResponsive">
                          <template xs12 v-for="(list, j) in Community">
                            <v-card flat :key="j">
                              <v-img :src="list.image" contain eager> <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                                  </v-row>
                                </template></v-img>
                            </v-card>
                          </template>
                        </OwlCarousel>
                      </v-card>
                      <!-- <v-img contain :src="require('./../../assets/images/community.jpg')">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img> -->
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2 pb-6 pa-md-4 pa-lg-0 py-lg-4>
              <v-flex xs12 align-self-center>
                <v-card outlined flat tile color="#ffffff" style="border: 2px solid black">
                  <v-layout wrap pa-2>
                    <v-flex xs12 align-self-center text-center pa-2>
                      <span class="poppinssemibold">
                        While we continue investing in our planet, we seek your support to fund our initiatives on this
                        Earth Day.
                      </span>
                    </v-flex>

                    <v-flex xs12 align-self-center text-center pa-2>
                      <v-btn tile large dark color="#527E33" @click="
                        $vuetify.breakpoint.name == 'xs' ||
                          $vuetify.breakpoint.name == 'sm'
                          ? scrollMeXS()
                          : scrollMe()
                      ">
                        <span class="poppinsbold" :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:17px'
                            : 'font-size:24px'
                        ">
                          Make a Donation
                        </span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2 pb-6 pa-md-4 pa-lg-0 py-lg-4 pb-lg-8>
              <v-flex xs12 align-self-center>
                <v-card outlined flat tile color="#527E33">
                  <v-layout wrap pa-2>
                    <v-flex xs12 sm3 align-self-center text-center pa-2>
                      <v-img contain height="100px" :src="require('./../../assets/images/wtilogo.png')">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>

                    <v-flex xs12 sm9 align-self-center text-sm-left text-xs-center pa-2 style="color: #ffffff">
                      <span class="poppinsbold">
                        FOR A SECURE NATURAL HERITAGE OF INDIA
                      </span>
                      <br />
                      <br />
                      <span class="poppinssemibold">
                        CONTACT INFO
                        <br />
                        <span class="poppinsregular">
                          Address: F-13, Sector 8, National Capital Region
                          (NCR), Noida 201301, India Email: info@wti.org.in
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      fab: true,
      slideNo: 0,
      slideNo1: 0,
      slideInterval: 3000,
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 1, nav: false },
        960: { items: 1, nav: false },
        1264: { items: 1, nav: false },
      },
      Habitat: [
        { image: require("./../../assets/images/habitat.jpg") },
        { image: require("./../../assets/images/garo.jpeg") }
      ],
      Community: [
        { image: require("./../../assets/images/kashmir.jpeg") },
        { image: require("./../../assets/images/community.jpg") },
      ]
    };
  },
  computed: {
    sliderHeight() {
      var height = "100%";
      if (this.$vuetify.breakpoint.xl) height = "100%";
      if (this.$vuetify.breakpoint.lg) height = "100%";
      if (this.$vuetify.breakpoint.md) height = "100%";
      if (this.$vuetify.breakpoint.sm) height = "100%";
      if (this.$vuetify.breakpoint.xs) height = "100%";
      return height;
    },
  },
  methods: {
    scrollMe() {
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;

      document.getElementById("donateNow").scrollIntoView({
        behavior: "smooth",
      });
    },
    scrollMeXS() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      document.getElementById("donateNow").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}

.bannerGrad {
  background: transparent;

  background-image: linear-gradient(104deg,
      #bc4639 6%,
      #7e7e7e2a 94%,
      transparent 100%);
}

.rot {
  animation-duration: 3s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.texth {
  line-height: 1.5em;
  min-height: 9.5em;
  float: left;
  width: 100%;
}

.hovCls {
  color: #005f32;
  border-bottom: 3px solid #005f32;
}

.hovCls:hover {
  color: blue;
  border-bottom: 3px solid blue;
}
</style>
